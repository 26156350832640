import React from "react"
import { connect } from "react-redux"
import Link from "gatsby-link"
import GoTrue from "gotrue-js"
import Navigation from "../components/navigation"
import styles from "./navigation.module.css"
import { modifyUser } from "./../actions"
import { graphql, navigate } from "gatsby"
import { window, document } from "browser-monads"
import { BrowserView, MobileView } from "react-device-detect"

const netlifyIdentity = require("netlify-identity-widget")
const NETLIFY_FUNC = "farajaraftingchallenge.org/.netlify/functions"
const LOCAL_FUNC = "localhost:9000"

const API_URL = process.env.NODE_ENV === "development" ? `http://${LOCAL_FUNC}` : `https://${NETLIFY_FUNC}`;

const auth = new GoTrue({
  APIUrl: "https://farajaraftingchallenge.org/.netlify/identity",
  audience: "",
  setCookie: false
})

class Header extends React.Component {
  constructor(data) {
    super(data)

    this.state = {
      products: []
    }
  }
  componentDidMount() {
    let url = `${window.location.href}`.split("/") || `https://farajaraftingchallenge.org/`.split("/")
    let route = url[url.length - 2] //minus 1 if url does not end with slash, else 2
    const user = auth.currentUser()
      this.props.modifyUser(user)
    netlifyIdentity.init()
    netlifyIdentity.on("login", () => {
      const user = auth.currentUser()
      this.props.modifyUser(user)
      if (user) this.navigateUserOnLogin(user, route)
      netlifyIdentity.close()
    })
    netlifyIdentity.on("logout", () => {
      navigate("/")
      let user = auth.currentUser()
      if (user)
        user
          .logout()
          .then(response => {
            this.props.modifyUser(null)
            let user = auth.currentUser()
          })
          .catch(err => console.log(err))
      netlifyIdentity.close()
    })
  }

  navigateUserOnLogin = (user, route) => {
    let shouldNavToAdmin = false
    Object.keys(user.app_metadata).forEach(item => {
      if (item === "roles") {
        user.app_metadata.roles.find(role => {
          if (role === "admin") {
            shouldNavToAdmin = true
            return
          }
        })
      }
    })
    if (shouldNavToAdmin) {
      navigate("/admin/")
      return
    } else {
      fetch(`${API_URL}/queryForTeam?teamId=${user.id}`)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              const teamData = data.data.filter(
                entry => entry[0] === user.id
              )[0] //first element
              if (
                (teamData !== undefined && teamData !== null) ||
                teamData.length > 0
              ) {
                navigate("/team/")
                return
              } else {
                navigate("/createTeam/")
                return
              }
            })
          } else {
            response.json().then(error => {
              console.log(
                `Request failed - Status code ${response.status}\nError:`,
                error
              )
              navigate("/createTeam/")
              return
            })
          }
        })
        .catch(err => {
          console.log(`Fetch error: ${err}`)
          navigate("/createTeam/")
          return
        })
    }
  }

  render() {
    return (
      <div id="header" className="container-fluid">
        <div className={styles.nav_wrapper}>
          <div className="main_nav">
            <div className="row justify-content-between">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div id="logo">
                  {" "}
                  <Link to="/">#PaddleForCancer</Link>
                </div>
              </div>

              <div className="col-lg-8 col-md-12 col-sm-12">
                <Navigation />
              </div>
            </div>
          </div>
          <div className="mobile_nav">
            <Navigation />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reduxUser: state.user
})

const mapDispatchToProps = () => {
  return {
    modifyUser
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(Header)
