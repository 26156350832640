import React from "react"
import Link from "gatsby-link"
import { navigate } from "gatsby"
import {window, document} from "browser-monads"
import { connect } from "react-redux"
import GoTrue from "gotrue-js"
import disableScroll from 'disable-scroll';
import {isMobileOnly} from "react-device-detect"
import styles from "./navigation.module.css"
import "mdbreact/dist/css/mdb.css"
const netlifyIdentity = require("netlify-identity-widget")

const auth = new GoTrue({
  APIUrl: "https://farajaraftingchallenge.org/.netlify/identity",
  audience: "",
  setCookie: false
})

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseID: "",
      isMobileNavActive: false
    }
  }

  componentDidMount(){
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize = () => {
    if(window.innerWidth > 760){
      this.setState({isMobileNavActive: false})
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this))
  }
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }))
  }
  toggleMobileNav = () => {
    this.setState({ isMobileNavActive: !this.state.isMobileNavActive })
  }
  scrollPreventionOnActiveNav = () => {
    if(this.state.isMobileNavActive){
      disableScroll.on()
    } else{
      disableScroll.off()
    }
  }
  render() {
    this.scrollPreventionOnActiveNav()
    const user = this.props.reduxUser
    let isLoggedIn = user ? true : false
    return (
      <React.Fragment>
        <div
          className={`mobile_nav ${
            this.state.isMobileNavActive ? "active" : ""
          }`}
        >
          <div className="row">
            <div className="col-9">
              <Link
                to="/"
                style={{ fontSize: "20px" }}
                id={"mobileNavLogo"}
              >
              #PaddleForCancer
              </Link>
            </div>
            <div className="col-3">
              <div id="mobileIcon" onClick={this.toggleMobileNav}>
                <span className="bar1"></span>
                <span className="bar2"></span>
                <span className="bar3"></span>
              </div>
            </div>
          </div>

          <div className="mobile_menu_list">
            <ul className={styles.navigation}>
              <li className={styles.navigationItem}>
                <Link to="/register-new/">Registration</Link>
              </li>
              <li className={styles.navigationItem}>
                <Link to="/sponsorship/">Sponsorship</Link>
              </li>
              <li className={styles.navigationItem}>
                <Link to="/fundraising/">Fundraising</Link>
              </li>
              <li className={styles.navigationItem}>
                <Link to="/gallery/">Gallery</Link>
              </li>
              <li className={styles.navigationItem}>
                <Link to="/contact/">Contact</Link>
              </li>
              <li className={`${styles.navigationItem} ${styles.loginBtn}`}>
                <a
                  style={{
                    borderTopRightRadius: 50,
                    borderTopLeftRadius: 50,
                    borderBottomLeftRadius: 50,
                    borderBottomRightRadius: 50,
                    padding: "7px 20px",
                    outline: "none",
                    background: "#E4419E",
                    color: "#fff",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    netlifyIdentity.open("login")
                  }}
                >
                  {isLoggedIn ? "Logout" : "Login"}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="main_nav">
          <nav role="navigation fixed-top">
            <ul className={styles.navigation}>
              <li className={styles.navigationItem}>
                <Link to="/register-new/">Registration</Link>
              </li>
              <li className={styles.navigationItem}>
                <Link to="/sponsorship/">Sponsorship</Link>
              </li>
              <li className={styles.navigationItem}>
                <Link to="/fundraising/">Fundraising</Link>
              </li>
              <li className={styles.navigationItem}>
                <Link to="/gallery/">Gallery</Link>
              </li>
              <li className={styles.navigationItem}>
                <Link to="/contact/">Contact</Link>
              </li>
              <li className={`${styles.navigationItem} ${styles.loginBtn}`}>
                <a
                  style={{
                    borderTopRightRadius: 50,
                    borderTopLeftRadius: 50,
                    borderBottomLeftRadius: 50,
                    borderBottomRightRadius: 50,
                    padding: "7px 20px",
                    outline: "none",
                    background: "#E4419E",
                    color: "#fff",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    netlifyIdentity.open("login")
                  }}
                >
                  {isLoggedIn ? "Logout" : "Login"}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  reduxUser: state.user
})

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps())(Navigation)
